    import './scss/main.scss'
    import { gsap } from "gsap";
    const screenHeight = () => {
        var vh = window.innerHeight;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    const mq = window.matchMedia('(max-width: 840px)');

    function init(){

    }

    window.onload = (event) => {
        console.log('page is fully loaded');
        init();
        screenHeight();
    };

    window.addEventListener('resize', screenHeight())

    const delay= 3;
    const tl_slider1 = gsap.timeline( { delay: delay, onComplete: function() {tl_slider1.restart(); } })
        // first_ interaction
        .to('.hero__slider .hero__slider--picture:nth-child(1)', { xPercent: -100, duration: delay,  ease: 'power3.inOut' })
        .from('.hero__slider .hero__slider--picture:nth-child(2)', { xPercent: 100, duration: delay,  ease: 'power3.inOut' },'<')
        // .addPause()

        // second interaction
        .to('.hero__slider .hero__slider--picture:nth-child(2)', { xPercent: -100, duration: delay,  ease: 'power3.inOut' }, '+=4')
        .from('.hero__slider .hero__slider--picture:nth-child(3)', { xPercent: 100, duration: delay,  ease: 'power3.inOut' },'<')
        // .addPause()

        // third interaction
        .to('.hero__slider .hero__slider--picture:nth-child(3)', { xPercent: -100, duration: delay,  ease: 'power3.inOut' }, '+=4')
        .from('.hero__slider .hero__slider--picture:nth-child(4)', { xPercent: 100, duration: delay,  ease: 'power3.inOut' },'<')
        .addLabel("end")
        .addPause("end+=3")


